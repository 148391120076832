var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Sizing"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "demo-vertical-spacing"
  }, [_c('b-input-group', {
    attrs: {
      "prepend": "@",
      "size": "lg"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Username"
    }
  })], 1), _c('b-input-group', {
    attrs: {
      "prepend": "@"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Username"
    }
  })], 1), _c('b-input-group', {
    attrs: {
      "size": "sm",
      "prepend": "@"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Username"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }